/********************/
/* Topic Display */
/********************/

#topic-name {
  font-size: 45px;
  font-weight: bold;
  margin-left: 4%;
  margin-top: 3%;
  margin-bottom: 3%;
}

#topic-buttons {
  min-width: 300px;
  max-width: 450px;
}

#topic-description {
  min-width: 300px;
  max-width: 650px;
}
