/********************/
/* Question Display */
/********************/

#question {
  margin-top: 100px;
  font-size: 45px;
}

/**********/
/* Footer */
/**********/
#footer {
  display: inline-block;
  color: #999;
  font-size: 10px;
  margin: 50px auto 30px auto;
  text-align: center;
  width: 100%;
  bottom: 0;
}

#footer a {
  text-decoration: none;
  color: #98c9a3;
}

#footer.warning {
  font-weight: 600;
  color: crimson;
}

/***********/
/* Results */
/***********/

#score-box {
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  background-color: #77bfa3;
  display: inline-block;
  font-size: 40px;
  color: #fff;
  padding: 40px 30px 40px 30px;
  z-index: 2;
}

li.url {
  color: #999;
}

.correct-answer {
  font-weight: 800;
  color: #77bfa3;
}

.incorrect-answer {
  font-weight: 800;
  color: crimson;
}

#phishing-category {
  margin-left: 30px;
}

#phishing-category span {
  font-weight: bolder;
}

mark {
  background-color: transparent;
}


/**************/
/* Url Review */
/**************/

#url-review {
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  padding: 10px;
}

#url-review li {
  list-style-type: none;
}

.contracted {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s ease-out;
}

.expanded {
  max-height: 100vh;
  z-index: 11;
  overflow: hidden;
  transition: max-height 0.4s ease-in;
}

#url-structure {
  list-style-type: none;
  margin-top: 60px;
  margin-bottom: 10px;
  font-family: "Rubik", sans-serif;
  font-size: 22px;
}

#url-structure li {
  display: inline-block;
}

#example-urls {
  margin-bottom: 20px;
  color: #999;
}
