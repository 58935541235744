/****************/
/* Landing Page */
/****************/

#title {
  margin-top: 120px;
}

#title header {
  font-size: 80px;
  margin: 15px;
}

#title h4 {
  color: #999;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

#title ul.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  margin: 50px auto 50px auto;
}

a {
  text-decoration: none;
  color: #98c9a3;
}
