/********************/
/* Action List */
/********************/

#category {
  color: #54af8c;
  font-size: 20px;
  font-weight: bold;
}

#actions header {
    color: #54af8c;
    font-weight: bold;
    text-decoration: underline;
}

.category-header {
  margin-bottom: 8px;
}

#action-steps {
  list-style: none;
  padding: 0;
  margin-top: 5px;
}

#action_list {
  list-style-type: none;
  padding-left: 0;
}

.arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 8px;
  border-style: solid;
}

.arrow.collapsed {
  border-width: 5px 0 5px 8px;
  border-color: transparent transparent transparent #54af8c;
}

.arrow.expanded {
  border-width: 8px 5px 0 5px;
  border-color: #54af8c transparent transparent transparent;
}