@import url("https://fonts.googleapis.com/css?family=Raleway|Rubik&display=swap");

/************/
/* Defaults */
/************/

body {
  font-family: "Raleway", sans-serif;
}

@media (max-width: 480px) {
  .text {
    font-size: 35px;
  }
}

.container {
  margin: 0px auto 0px auto;
  width: 80%;
}

@media (max-width: 480px) {
  .container {
    width: 95%;
  }
}

/*********/
/* Forms */
/*********/

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  height: 38px;
  padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  /* Removes awkward default styles on some inputs for iOS */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea {
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px;
}

label,
legend {
  display: block;
  margin-bottom: 0.5rem;
}

fieldset {
  padding: 0;
  border-width: 0;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
}

input:focus,
textarea:focus {
  outline: none !important;
  border-color: #98c9a3;
  box-shadow: none;
}

input[type="checkbox"] {
  float: left;
  margin-right: 5px;
  appearance: none;
  background-color: #fff;
  width: 1.15em;
  height: 1.15em;
  border: 0.11em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #54af8c;
  background-color: CanvasText;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

/***********/
/* Buttons */
/***********/

.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-block;
  height: 38px;
  padding: 0 30px;
  color: #555;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  line-height: 38px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #bbb;
  cursor: pointer;
  box-sizing: border-box;
}

.vertical-buttons {
  display: inline-block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.button:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
  color: #333;
  border-color: #5f5f5f;
  background-color: #f6fbf9;
  outline: 0;
}

.button.button-primary,
button.button-primary,
input[type="submit"].button-primary,
input[type="reset"].button-primary,
input[type="button"].button-primary {
  color: #fff;
  background-color: #98c9a3;
  border-color: #98c9a3;
}

.button.button-primary:hover,
button.button-primary:hover,
input[type="submit"].button-primary:hover,
input[type="reset"].button-primary:hover,
input[type="button"].button-primary:hover,
.button.button-primary:focus,
button.button-primary:focus,
input[type="submit"].button-primary:focus,
input[type="reset"].button-primary:focus,
input[type="button"].button-primary:focus {
  color: #fff;
  background-color: #77bfa3;
  border-color: #77bfa3;
}

/*********/
/* Lists */
/*********/

ul {
  list-style: circle inside;
}

ol {
  list-style: decimal inside;
}

ol,
ul {
  padding-left: 0;
  margin-top: 0;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin: 1.5rem 0 1.5rem 3rem;
}

ul p,
ol p,
ul header,
ol header {
  margin: 0.75rem 0 1.5rem 1.2rem;
}

li {
  margin-bottom: 1rem;
}

/***********/
/* Columns */
/***********/
.column {
  position: relative;
  width: 100%;
  max-width: 960px;
  box-sizing: border-box;
  float: left;
  flex: 1;
}

.left-column {
  min-width: 300px;
  max-width: 450px;
  width: 45%;
  margin-left: 4%;
  margin-right: 1%;
}

.right-column {
  min-width: 300px;
  max-width: 650px;
  width: 45%;
  margin-right: 4%;
  margin-left: 1%;
}

.column-container {
  display: flex;
  flex-flow: row wrap;
  margin: 0px auto 0px auto;
  z-index: 1;
}

@media (max-width: 480px) {
  .left-column,
  .right-column {
    width: 100%;
  }
}

/***********/
/* Banners */
/***********/
.banner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.banner-title {
  font-family: "Rubik", sans-serif;
  color: #77bfa3;
  margin-left: 20px;
  margin-right: 20px;
}
